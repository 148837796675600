import { SOURCE } from "../../types/Enums";

const EntraIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path d="m17.558,9.638L10.436,1.632c-.358-.402-.881-.632-1.435-.632h0c-.554,0-1.078.231-1.435.632L.442,9.638c-.346.389-.5.9-.423,1.403.077.503.379.952.827,1.231l7.123,4.436c.313.195.672.293,1.031.293s.718-.098,1.031-.293l7.123-4.436c.448-.279.75-.728.827-1.231.077-.503-.077-1.014-.423-1.403Zm-8.557,2.564l-3.47-2.161,3.47-3.9,3.47,3.9-3.47,2.161Zm-4.821-1.975l3.995,2.489-1.137.708-.016.01c-.394.25-.854.382-1.33.382-.528,0-1.059-.214-1.38-.413l-2.969-1.849c-.235-.147-.387-.373-.428-.637-.04-.264.037-.521.219-.725L8.257,2.186c.185-.208.456-.327.743-.328.287,0,.558.119.743.328l1.576,1.771c-.009,0-.018,0-.028,0-.856,0-1.668.313-2.278.866l-4.833,5.404Zm12.905.69c-.041.264-.192.49-.428.637l-7.123,4.436c-.325.202-.744.202-1.068,0l-2.192-1.365c.451-.076.88-.238,1.264-.484l6.259-3.898-4.197-4.759c.448-.425,1.053-.667,1.692-.667.335,0,.661.07.97.201l4.605,5.175c.181.204.259.461.219.725Z" />
    </svg>
  );
};

const DatabaseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
      <path d="M960 128q72 0 159 8t175 27 167 54 133 85q31 29 50 66t20 80v1152q0 45-19 80t-51 66q-52 51-131 84t-168 54-177 28-158 8q-72 0-159-8t-175-27-167-54-133-85q-31-29-50-66t-20-80V448q0-45 19-80t51-66q52-51 131-84t168-54 177-28 158-8zm0 128q-51 0-106 3t-112 12-109 22-102 33q-15 6-40 18t-49 29-41 35-17 40q0 8 3 15t8 14q21 32 60 56t89 42 106 30 111 20 107 11 92 4q40 0 91-3t107-11 112-20 105-31 89-42 61-56q5-7 8-14t3-15q0-20-17-39t-41-36-49-28-40-19q-48-19-101-32t-110-22-111-12-107-4zm0 1536q51 0 106-3t112-12 109-22 102-33q15-6 40-18t49-29 41-35 17-40V638q-57 37-129 62t-149 40-155 21-143 7q-66 0-143-6t-155-22-149-40-129-62v962q0 20 17 39t41 36 49 28 40 19q48 20 101 33t110 21 111 12 107 4z"></path>
    </svg>
  );
};

const OutlookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
      <path d="M2048 1005v929q0 24-9 44t-24 36-36 25-45 9H370q-24 0-44-9t-36-24-25-36-9-45v-270H128q-26 0-49-10t-41-27-28-41-10-50V512q0-26 10-49t27-41 41-28 50-10h256V128q0-27 10-50t27-40 41-28 50-10h1280q27 0 50 10t40 27 28 41 10 50v776l102 58q11 7 18 18t8 25zm-896-749v128q26 0 49 10t41 27 28 41 10 50v256h512V255l-640 1zm640 640h-512v423l512-295V896zM512 256v128h512V256H512zm126 1184q95 0 172-31t131-87 83-134 30-172q0-91-28-166t-82-129-127-84-166-30q-97 0-175 30t-133 86-85 134-30 175q0 92 30 167t84 128 129 83 167 30zm-254 480h1429l-558-312q-18 25-45 40t-58 16H384v256zm1536-87v-731l-1-1v2l-639 364v9l640 357zM454 1024q0-45 11-86t35-72 60-50 85-19q51 0 86 19t57 51 31 73 10 87q0 46-10 86t-34 71-59 47-86 18q-49 0-84-18t-57-50-34-72-11-85z"></path>
    </svg>
  );
};

const SharePointIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
      <path d="M2048 1152q0 106-40 199t-110 162-163 110-199 41q-65 0-130-17-9 85-47 158t-99 128-137 84-163 31q-93 0-174-35t-142-96-96-142-36-175q0-16 1-32t4-32H85q-35 0-60-25t-25-60V597q0-35 25-60t60-25h302q12-109 62-202t127-163T751 39 960 0q119 0 224 45t183 124 123 183 46 224q0 16-1 32t-3 32q106 0 200 40t164 109 111 162 41 201zM960 128q-83 0-158 29t-135 80-98 122-52 153h422q35 0 60 25t25 60v422l18-3q18-64 52-121t80-104 104-81 122-52q8-43 8-82 0-93-35-174t-96-142-142-96-175-36zM522 1357q46 0 90-9t80-32 56-58 22-90q0-54-22-90t-57-60-73-39-73-29-56-29-23-38q0-17 12-27t28-17 35-8 30-2q51 0 90 13t81 39V729q-24-7-44-12t-41-8-41-5-48-2q-44 0-90 10t-83 32-61 58-24 88q0 51 22 85t57 58 73 40 73 31 56 31 23 39q0 19-10 30t-27 17-33 7-32 2q-60 0-106-20t-95-53v160q102 40 211 40zm438 563q66 0 124-25t101-68 69-102 26-125q0-57-19-109t-53-93-81-71-103-41v165q0 35-25 60t-60 25H646q-6 32-6 64 0 66 25 124t68 102 102 69 125 25zm576-384q79 0 149-30t122-82 83-122 30-150q0-79-30-149t-82-122-123-83-149-30q-79 0-148 30t-122 83-83 122-31 149v22q0 11 2 22 47 23 87 55t71 73 54 88 33 98q67 26 137 26z"></path>
    </svg>
  );
};

export type SourceIconProps = {
  sourceType?: string;
};
const SourceIcon = (props: SourceIconProps): JSX.Element => {
  switch (props.sourceType) {
    case SOURCE.AZURE_AD:
      return <EntraIcon />;
    case SOURCE.ORG_CONTACT:
      return <OutlookIcon />;
    case SOURCE.USER_CONTACT:
      return <OutlookIcon />;
    case SOURCE.SHAREPOINT:
      return <SharePointIcon />;
    case SOURCE.DATABASE:
      return <DatabaseIcon />;
    default:
      return <></>;
  }
};

export default SourceIcon;
