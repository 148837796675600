import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import {
  getAdminControllerGetAllSettingValuesOfCategory,
  getAdminControllerGetSettingsValue,
  postAdminControllerSetAllSettingValuesOfCategory,
  postAdminControllerSetSettingsValue,
} from "../../../services/ApiService";
import { HashTable, Loadable } from "../../../types/Types";
import Button from "react-bootstrap/esm/Button";
import { Trash } from "react-bootstrap-icons";
import Overlay from "react-bootstrap/esm/Overlay";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { useAdminPageEnvironmentContext } from "../../../providers/AdminPageEnvironmentContextProvider";
import { EUnifiedContactsEdition } from "../../../types/Enums";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

const diagnosticOptionsCodeToStringDict: HashTable<string> = {
  "0": "Off",
  "1": "Anonymous",
  "2": "General",
  "4": "Detailed",
};

const diagnosticOptionsStringToCodeDict: HashTable<string> = {
  Off: "0",
  Anonymous: "1",
  General: "2",
  Detailed: "4",
};

type TSourceDefinitionState = {
  id: string;
  displayName: string;
  enabled: boolean;
  order: number;
};
type TSbcLookupSettingsState = {
  endpointEnabled: boolean;
  ipAuthenticationEnabled: boolean;
  allowedIpAddresses: string[];
  credentialsLastModifiedBy?: string;
  credentialsLastModified?: string;
};
type TSbcValidationsState = {
  userNameInvalid: boolean;
  passwordInvalid: boolean;
  userNameInvalidMessage: string;
  passwordInvalidMessage: string;
};
type TIpAddressInputValidationState = {
  isInvalid: boolean;
  invalidMessage: string;
};

const enabledSourcesDetails: HashTable<{ displayName: string; order: number }> =
  {
    azuread: { displayName: "Entra Id", order: 1 },
    usercontacts: { displayName: "User Contacts", order: 2 },
    orgcontacts: { displayName: "Org Contacts", order: 3 },
    sharepoint: { displayName: "SharePoint", order: 4 },
    database: { displayName: "Database", order: 5 },
  };

export function AdminPageContentSettings() {
  const applySbcEndpointCredentialsButton = useRef(null);

  const [selectedDiagnosticLevel, setSelectedDiagnosticLevel] =
    useState<string>();
  const [enabledSources, setEnabledSources] = useState<
    Loadable<TSourceDefinitionState[]>
  >({
    loading: true,
  });
  const [sbcSettings, setSbcSettings] = useState<
    Loadable<TSbcLookupSettingsState>
  >({
    loading: true,
  });
  const [sbcCredentialValidations, setSbcCredentialValidations] =
    useState<TSbcValidationsState>({
      userNameInvalid: false,
      passwordInvalid: false,
      userNameInvalidMessage: "",
      passwordInvalidMessage: "",
    });
  const [tempSbcIpAddressInput, setTempSbcIpAddressInput] =
    useState<string>("");
  const [tempSbcIpAddressInputValidation, setTempSbcIpAddressInputValidation] =
    useState<TIpAddressInputValidationState>({
      isInvalid: false,
      invalidMessage: "",
    });

  const [sbcEndpointUsername, setSbcEndpointUsername] = useState<string>("");
  const [sbcEndpointPassword, setSbcEndpointPassword] = useState<string>("");
  const [sbcEndpointCredentialSuccess, setSbcEndpointCredentialSuccess] =
    useState<boolean>(false);
  const [sbcEndpointCredentialLoading, setSbcEndpointCredentialLoading] =
    useState<boolean>(false);

  const environmentContext = useAdminPageEnvironmentContext();

  const isProEdition = useMemo(() => {
    return (
      environmentContext.versionInfo?.edition === EUnifiedContactsEdition.PRO
    );
  }, [environmentContext.versionInfo?.edition]);

  const initSbcSettings = (): void => {
    getAdminControllerGetAllSettingValuesOfCategory("sbcLookup").then(
      (response) => {
        if (response) {
          let allowedIpAddresses: string[] = [];
          const allowedIpAddressesString = response.settings.find(
            (setting) => setting.settingId === "allowedIpAddresses"
          )?.value;
          if (allowedIpAddressesString) {
            allowedIpAddresses = allowedIpAddressesString.split(";");
          }

          const sbcSettings: TSbcLookupSettingsState = {
            endpointEnabled:
              response.settings.find(
                (setting) => setting.settingId === "endpointEnabled"
              )?.value === "1",
            ipAuthenticationEnabled:
              response.settings.find(
                (setting) => setting.settingId === "ipAuthenticationEnabled"
              )?.value === "1",
            allowedIpAddresses: allowedIpAddresses,
            credentialsLastModifiedBy: response.settings.find(
              (setting) =>
                setting.settingId === "endpointCredentialsLastModifiedBy"
            )?.value,
            credentialsLastModified: response.settings.find(
              (setting) =>
                setting.settingId === "endpointCredentialsLastModified"
            )?.value,
          };
          setSbcSettings({
            loading: false,
            data: sbcSettings,
          });
        } else {
          setSbcSettings({
            loading: false,
            data: {
              endpointEnabled: false,
              ipAuthenticationEnabled: false,
              allowedIpAddresses: [],
            },
          });
        }
      }
    );
  };

  const initEnabledSources = (): void => {
    getAdminControllerGetAllSettingValuesOfCategory("enabledSources").then(
      (response) => {
        if (response) {
          const enabledSources: TSourceDefinitionState[] = [];
          response.settings.forEach((setting) => {
            enabledSources.push({
              id: setting.settingId,
              displayName:
                enabledSourcesDetails[setting.settingId].displayName ??
                setting.settingId,
              enabled: setting.value === "1",
              order: enabledSourcesDetails[setting.settingId].order ?? 9999,
            });
          });

          setEnabledSources({
            loading: false,
            data: enabledSources.sort((a, b) => a.order - b.order),
          });
        } else {
          setEnabledSources({
            loading: false,
            data: [],
          });
        }
      }
    );
  };

  useEffect(() => {
    getAdminControllerGetSettingsValue("telemetry", "errorTelemetryLevel").then(
      (setting) => {
        if (setting.value) {
          setSelectedDiagnosticLevel(
            diagnosticOptionsCodeToStringDict[setting.value] ?? ""
          );
        } else {
          setSelectedDiagnosticLevel("");
        }
      }
    );

    initSbcSettings();
    initEnabledSources();
  }, []);

  // SBC Credentials validation
  const validateUsername = (username: string) => {
    let isValid = true;
    let invalidMessage = "";

    if (username && username.length < 3) {
      isValid = false;
      invalidMessage += "Username must be at least 3 characters long. ";
    }

    const notAllowedCharactersRegex = /[^a-zA-Z0-9]+/g;
    if (username && username.match(notAllowedCharactersRegex) !== null) {
      isValid = false;
      invalidMessage +=
        "Username contains invalid characters. Only alphanumeric characters are allowed. ";
    }

    setSbcCredentialValidations((prev) => ({
      ...prev,
      userNameInvalid: !isValid,
      userNameInvalidMessage: invalidMessage,
    }));
  };

  const validatePassword = (password: string) => {
    let isValid = true;
    let invalidMessage = "";

    if (password && password.length < 16) {
      isValid = false;
      invalidMessage += "Password must be at least 16 characters long. ";
    }

    const containsSpecialCharacterRegex = /[!?@#$%^&*()_+\-=[\]{}]+/g;
    if (password && password.match(containsSpecialCharacterRegex) === null) {
      isValid = false;
      invalidMessage +=
        "Password must contain at least one special character (!?@#$%^&*()_+-=[]{}). ";
    }

    const notAllowedCharactersRegex = /[^a-zA-Z0-9!?@#$%^&*()_+\-=[\]{}]+/g;
    if (password && password.match(notAllowedCharactersRegex) !== null) {
      isValid = false;
      invalidMessage +=
        "Password contains invalid characters. Valid characters are alphanumeric and !?@#$%^&*()_+-=[]{}. ";
    }

    setSbcCredentialValidations((prev) => ({
      ...prev,
      passwordInvalid: !isValid,
      passwordInvalidMessage: invalidMessage,
    }));
  };

  // SBC IP Address validation
  const validateIpAddress = (ipAddress: string) => {
    const ipAddressValidationRegex =
      /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])))$/g;
    if (ipAddress && ipAddress.match(ipAddressValidationRegex) === null) {
      setTempSbcIpAddressInputValidation({
        isInvalid: true,
        invalidMessage: "Please enter a valid IPv4 or IPv6 address.",
      });
    } else {
      setTempSbcIpAddressInputValidation({
        isInvalid: false,
        invalidMessage: "",
      });
    }
  };

  //SBC lookup endpoint settings functions
  const applySbcCredentials = (): void => {
    setSbcEndpointCredentialSuccess(false);
    setSbcEndpointCredentialLoading(true);
    postAdminControllerSetSettingsValue(
      "sbcLookup",
      "endpointCredentials",
      `${sbcEndpointUsername}:${sbcEndpointPassword}`,
      true
    )
      .then(() => {
        setSbcEndpointCredentialSuccess(true);
        setTimeout(() => {
          setSbcEndpointCredentialSuccess(false);
        }, 3000);
      })
      .finally(() => {
        setSbcEndpointCredentialLoading(false);
        initSbcSettings();
      });
  };

  const saveCurrentSbcSettingsExcludingCredentials = (
    state?: TSbcLookupSettingsState
  ): void => {
    console.log(state?.allowedIpAddresses);
    postAdminControllerSetAllSettingValuesOfCategory(
      "sbcLookup",
      [
        {
          settingId: "endpointEnabled",
          value: state?.endpointEnabled ? "1" : "0",
        },
        {
          settingId: "ipAuthenticationEnabled",
          value: state?.ipAuthenticationEnabled ? "1" : "0",
        },
        {
          settingId: "allowedIpAddresses",
          value: state?.allowedIpAddresses.join(";"),
        },
      ],
      true
    ).catch((error) => {
      console.error("Error while saving SBC settings", error);
      initSbcSettings();
    });
  };

  const addIpAddress = (): void => {
    const newData: TSbcLookupSettingsState | undefined = sbcSettings.data;
    newData?.allowedIpAddresses.push(tempSbcIpAddressInput);
    setSbcSettings((prev) => {
      return {
        ...prev,
        data: newData,
      };
    });
    setTempSbcIpAddressInput("");
    saveCurrentSbcSettingsExcludingCredentials(newData);
  };

  const removeIpAddress = (ipAddressToRemove: string): void => {
    const newData: TSbcLookupSettingsState | undefined = sbcSettings.data;
    if (newData) {
      newData.allowedIpAddresses = newData?.allowedIpAddresses.filter(
        (ip) => ip !== ipAddressToRemove
      );
    }
    setSbcSettings((prev) => {
      return {
        ...prev,
        data: newData,
      };
    });
    saveCurrentSbcSettingsExcludingCredentials(newData);
  };

  const onToggleEnableSbcLookupEndpoint = (enabled: boolean): void => {
    const newData: TSbcLookupSettingsState | undefined = sbcSettings.data;
    if (newData) {
      newData.endpointEnabled = enabled;
    }
    setSbcSettings((prev) => {
      return {
        ...prev,
        data: newData,
      };
    });
    saveCurrentSbcSettingsExcludingCredentials(newData);
  };

  const onToggleEnableIpAuthentication = (enabled: boolean): void => {
    const newData: TSbcLookupSettingsState | undefined = sbcSettings.data;
    if (newData) {
      newData.ipAuthenticationEnabled = enabled;
    }
    setSbcSettings((prev) => {
      return {
        ...prev,
        data: newData,
      };
    });
    saveCurrentSbcSettingsExcludingCredentials(newData);
  };

  //Enable source functions
  const changeStateOfSingleEnabledSource = (
    sourceId: string,
    enabled: boolean
  ) => {
    const newEnabledSources = enabledSources.data
      ? [...enabledSources.data]
      : []; //deep copy
    const element = newEnabledSources.find((src) => src.id === sourceId);
    if (element) {
      element.enabled = enabled;
    }
    setEnabledSources({ loading: false, data: newEnabledSources });
  };

  const onToggleEnabledSource = (sourceId: string, enabled: boolean): void => {
    changeStateOfSingleEnabledSource(sourceId, enabled);
    postAdminControllerSetSettingsValue(
      "enabledSources",
      sourceId,
      enabled ? "1" : "0",
      true
    ).catch(() => {
      // revert state if error
      getAdminControllerGetSettingsValue("enabledSources", sourceId)
        .then((response) => {
          changeStateOfSingleEnabledSource(sourceId, response.value === "1");
        })
        .catch((error) => {
          console.error(
            `Error while toggling enable source of ${sourceId}`,
            error
          );
          changeStateOfSingleEnabledSource(sourceId, !enabled);
        });
    });
  };
  return (
    <>
      <h1>Settings</h1>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Group className="mb-3" controlId="diagnostics">
          <Form.Label>
            <h5>Diagnostics Error Log level</h5>
          </Form.Label>
          {selectedDiagnosticLevel ? (
            <Form.Select
              onChange={(event) => {
                setSelectedDiagnosticLevel(event.target.value);
                postAdminControllerSetSettingsValue(
                  "telemetry",
                  "errorTelemetryLevel",
                  diagnosticOptionsStringToCodeDict[event.target.value]
                );
              }}
              value={selectedDiagnosticLevel}
            >
              <option key="0">Off</option>
              <option key="1">Anonymous</option>
              <option key="2">General</option>
              <option key="3">Detailed</option>
            </Form.Select>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
          <Form.Text className="text-muted">
            Select your desired error log level which will be called back to the
            manufacturer. For more information click{" "}
            <a
              href="https://docs.unified-contacts.com/advanced-configuration/logs-and-telemetry"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group className="mb-3" controlId="diagnostics">
          <Form.Label>
            <h5>Enabled Sources</h5>
          </Form.Label>
          {enabledSources.loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            enabledSources.data?.map((source) => {
              return (
                <Form.Check
                  className="bootstrap__form-check--cursor-pointer"
                  type="switch"
                  key={source.id}
                  id={source.id}
                  label={source.displayName}
                  checked={source.enabled}
                  onChange={(event) => {
                    onToggleEnabledSource(source.id, event.target.checked);
                  }}
                />
              );
            })
          )}
          <Form.Text className="text-muted">
            Select the sources that your users should be able to search through.
            Changes are saved and applied automatically.
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group className="mb-3" controlId="diagnostics">
          <Form.Label>
            <h5>SBC Lookup</h5>
          </Form.Label>
          {sbcSettings.loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <>
              <Form.Group>
                <OverlayTrigger
                  show={isProEdition ? false : undefined}
                  overlay={<Tooltip>Pro only</Tooltip>}
                  placement="top"
                >
                  <div className={isProEdition ? "" : "pro-edition-locked"}>
                    <Form.Check
                      type="switch"
                      label="SBC Lookup Endpoint"
                      className="bootstrap__form-check--cursor-pointer"
                      checked={
                        (sbcSettings.data?.endpointEnabled ?? false) &&
                        isProEdition
                      }
                      disabled={!isProEdition}
                      style={{ cursor: "pointer" }}
                      onChange={(event) => {
                        onToggleEnableSbcLookupEndpoint(event.target.checked);
                      }}
                    />
                  </div>
                </OverlayTrigger>
              </Form.Group>
              {sbcSettings.data?.endpointEnabled && isProEdition && (
                <>
                  <Form.Group>
                    <Form.Check
                      type="switch"
                      label="IP Authentication"
                      className="bootstrap__form-check--cursor-pointer"
                      checked={
                        sbcSettings.data?.ipAuthenticationEnabled ?? false
                      }
                      style={{ cursor: "pointer" }}
                      onChange={(event) => {
                        onToggleEnableIpAuthentication(event.target.checked);
                      }}
                    />
                    {sbcSettings.data?.ipAuthenticationEnabled && (
                      <div className="admin-page__settings__ip-address">
                        <div
                          className="flex-container flex-container--dont-grow"
                          style={{ marginBottom: "0.125rem" }}
                        >
                          <Button
                            disabled={
                              tempSbcIpAddressInputValidation.isInvalid ||
                              !tempSbcIpAddressInput
                            }
                            onClick={addIpAddress}
                          >
                            +
                          </Button>
                          <div className="full-width">
                            <Form.Control
                              type="text"
                              placeholder="IP address"
                              value={tempSbcIpAddressInput}
                              onChange={(
                                val: ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              ) => {
                                setTempSbcIpAddressInput(val.target.value);
                                validateIpAddress(val.target.value);
                              }}
                              isInvalid={
                                tempSbcIpAddressInputValidation.isInvalid
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {tempSbcIpAddressInputValidation.invalidMessage}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <ListGroup>
                          {sbcSettings.data?.allowedIpAddresses.map((ip) => {
                            return (
                              <div key={ip} className="flex-container">
                                <ListGroup.Item className="full-width">
                                  {ip}
                                </ListGroup.Item>
                                <div
                                  onClick={() => {
                                    removeIpAddress(ip);
                                  }}
                                  className="admin-page__settings__ip-address__remove-action"
                                >
                                  <Trash />
                                </div>
                              </div>
                            );
                          })}
                        </ListGroup>
                      </div>
                    )}
                    <Form.Text className="text-muted">
                      Changes to IP Authentication and Endpoint enablement are
                      saved and applied automatically.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Basic Auth Credentials</Form.Label>
                    {sbcSettings.data?.credentialsLastModified && (
                      <div>
                        <Form.Text className="text-muted">
                          Last modified by {sbcSettings.data?.credentialsLastModifiedBy ?? "UNKNOWN"} at {sbcSettings.data?.credentialsLastModified}
                        </Form.Text>
                      </div>
                    )}
                    <div className="flex-container flex-container--dont-grow">
                      <div className="full-width">
                        <Form.Control
                          type="text"
                          placeholder="Username"
                          value={sbcEndpointUsername}
                          onChange={(
                            val: ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          ) => {
                            setSbcEndpointUsername(val.target.value);
                            validateUsername(val.target.value);
                          }}
                          isInvalid={sbcCredentialValidations.userNameInvalid}
                        />
                        <Form.Control.Feedback type="invalid">
                          {sbcCredentialValidations.userNameInvalidMessage}
                        </Form.Control.Feedback>
                      </div>
                      <div className="full-width">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={sbcEndpointPassword}
                          onChange={(
                            val: ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          ) => {
                            setSbcEndpointPassword(val.target.value);
                            validatePassword(val.target.value);
                          }}
                          isInvalid={sbcCredentialValidations.passwordInvalid}
                        />
                        <Form.Control.Feedback type="invalid">
                          {sbcCredentialValidations.passwordInvalidMessage}
                        </Form.Control.Feedback>
                      </div>
                      <Button
                        variant="primary"
                        onClick={applySbcCredentials}
                        ref={applySbcEndpointCredentialsButton}
                        disabled={
                          !sbcEndpointUsername ||
                          !sbcEndpointPassword ||
                          sbcCredentialValidations.userNameInvalid ||
                          sbcCredentialValidations.passwordInvalid ||
                          sbcEndpointCredentialLoading
                        }
                        style={{ width: "10rem" }}
                      >
                        {sbcEndpointCredentialLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          "Apply"
                        )}
                      </Button>
                      <Overlay
                        target={applySbcEndpointCredentialsButton.current}
                        show={sbcEndpointCredentialSuccess}
                        placement="left"
                      >
                        <Tooltip>Success!</Tooltip>
                      </Overlay>
                    </div>
                    <Form.Text className="text-muted">
                      NOTE: Once you leave this page you will not be able to see
                      the user name or the password. If you have forgotten the
                      credentials you have to re apply new ones.
                    </Form.Text>
                  </Form.Group>
                </>
              )}
            </>
          )}
        </Form.Group>
      </Form>
    </>
  );
}
