import { HostClientType, HostName, app, call, chat } from "@microsoft/teams-js";
import localizedStrings from "../loacalization/localization";
import { TeamsContextData } from "../providers/TeamsContextProvider";

export const triggerChat = (
  target: string,
  setTileActionAlert: (alertText: string) => Promise<void>, // eslint-disable-line @typescript-eslint/no-unused-vars
  teamsContextData?: TeamsContextData // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
  const url = `https://teams.microsoft.com/l/chat/0/0?users=${target}`;
  if (chat.isSupported()) {
    chat.openChat({ user: target }).catch(() => {
      openLinkWithErrorHandling(url, setTileActionAlert);
    });
  } else {
    openLinkWithErrorHandling(url, setTileActionAlert);
  }
};

export const triggerVideoCall = (
  target: string,
  setTileActionAlert: (alertText: string) => Promise<void>,
  teamsContextData?: TeamsContextData // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
  const url = `https://teams.microsoft.com/l/call/0/0?users=${target}&withVideo=true`;
  if (call.isSupported()) {
    call
      .startCall({
        targets: [target],
        requestedModalities: [call.CallModalities.Video],
      })
      .catch(() => {
        openLinkWithErrorHandling(url, setTileActionAlert);
      });
  } else {
    openLinkWithErrorHandling(url, setTileActionAlert);
  }
};

export const triggerPhoneCall = (
  target: string,
  setTileActionAlert: (alertText: string) => Promise<void>, // eslint-disable-line @typescript-eslint/no-unused-vars
  teamsContextData?: TeamsContextData // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
  let phone: HTMLAnchorElement | null = null;
  const escapedNumber = target
    .replaceAll(" ", "")
    .replaceAll("-", "")
    .replaceAll("/", "")
    .replaceAll("(0)", "")
    .replaceAll("(", "")
    .replaceAll(")", "");

  switch (teamsContextData?.clientType) {
    case HostClientType.web:
    case HostClientType.desktop:
      switch (teamsContextData.hostName) {
        case HostName.office:
        case HostName.outlook:
        case HostName.outlookWin32:
          phone = document.createElement("a");
          phone.target = "_blank";
          phone.href = `tel:${escapedNumber}`;
          phone.click();
          phone.remove();
          break;
        case HostName.teams:
        case HostName.teamsModern:
        default:
          app.openLink(`tel:${escapedNumber}`);
          break;
      }
      break;
    case HostClientType.android:
    case HostClientType.ios:
    case HostClientType.ipados:
    default:
      app.openLink(`https://teams.microsoft.com/l/call/0/0?users=4:${target}`);
  }

  // teamsJS left in code for potential fallback
  // if (call.isSupported()) {
  //   const actionPromise = call.startCall({
  //     targets: [
  //       (
  //         "4:" +
  //         props.phoneArray[0].substring(0, 1).replace("+", "00") +
  //         props.phoneArray[0].substring(1, props.phoneArray[0].length)
  //       ).replaceAll(" ", ""),
  //     ],
  //     requestedModalities: [call.CallModalities.Audio],
  //   });
  //   actionPromise.catch(() => {
  //     props.setTileActionAlert(localizedStrings.errorOccurred);
  //   });
  // } else {
  //   props.setTileActionAlert(localizedStrings.actionNotSupported);
  // }
  // }
};

export const triggerMail = (
  target: string,
  setTileActionAlert: (alertText: string) => Promise<void>, // eslint-disable-line @typescript-eslint/no-unused-vars
  teamsContextData?: TeamsContextData // eslint-disable-line @typescript-eslint/no-unused-vars
) => {
  let mail: HTMLAnchorElement | null = null;

  switch (teamsContextData?.hostName) {
    case HostName.outlook:
    case HostName.outlookWin32:
      mail = document.createElement("a");
      mail.href = `mailto:${target}`;
      mail.click();
      mail.remove();
      break;
    case HostName.office:
    case HostName.teams:
    case HostName.teamsModern:
    default:
      app.openLink(`mailto:${target}`);
      break;
  }
};

export const openLinkWithErrorHandling = (
  url: string,
  setTileActionAlert: (alertText: string) => Promise<void>
) => {
  app
    .openLink(url)
    .catch(() => setTileActionAlert(localizedStrings.errorOccurred));
};
