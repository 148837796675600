export default function LargeTileIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3752 0.736469L12.3817 0.743265L12.3885 0.749809C12.4627 0.821224 12.5204 0.904478 12.5629 1.0036C12.6039 1.09934 12.625 1.2008 12.625 1.3125V5.625H7.5V0.5H11.8125C11.9242 0.5 12.0257 0.52108 12.1214 0.562111C12.2205 0.604593 12.3038 0.662307 12.3752 0.736469ZM1.00846 0.566834L1.00848 0.566874L1.01452 0.564112C1.10788 0.521432 1.20551 0.5 1.3125 0.5H5.625V5.625H0.5V1.3125C0.5 1.20199 0.520611 1.10567 0.558717 1.01822C0.607795 0.911764 0.669519 0.823721 0.743202 0.750038C0.823755 0.669484 0.911644 0.609547 1.00846 0.566834ZM12.5609 12.1105L12.5608 12.1105L12.5582 12.1165C12.5155 12.2134 12.4555 12.3012 12.375 12.3818C12.3013 12.4555 12.2132 12.5172 12.1068 12.5663C12.0193 12.6044 11.923 12.625 11.8125 12.625H7.5V7.5H12.625V11.8125C12.625 11.9195 12.6036 12.0171 12.5609 12.1105ZM5.625 7.5V12.625H1.3125C1.21851 12.625 1.12514 12.6052 1.02819 12.5609C0.921668 12.5122 0.829869 12.4502 0.75013 12.3749C0.674777 12.2951 0.612808 12.2033 0.564112 12.0968C0.519795 11.9999 0.5 11.9065 0.5 11.8125V7.5H5.625Z"
        stroke="#C4C4C4"
      />
    </svg>
  );
}
