import { TILE_ACTIONS } from "../types/Enums";
import {
  triggerChat,
  triggerMail,
  triggerPhoneCall,
  triggerVideoCall,
} from "../services/ButtonActionService";
import { ReactElement } from "react";
import { MenuItem, MenuList, PopoverSurface } from "@fluentui/react-components";
import { useTeamsContext } from "../providers/TeamsContextProvider";

type TileButtonActionPopoverProps = {
  inputArray: string[];
  functionality: TILE_ACTIONS;
  setTileActionAlert: (alertText: string) => Promise<void>;
};

export const TileButtonActionPopover = (
  props: TileButtonActionPopoverProps
) => {
  const teamsContext = useTeamsContext();

  const clickMenuItem = (element: string) => {
    switch (props.functionality) {
      case TILE_ACTIONS.MAILTO:
        triggerMail(element, props.setTileActionAlert, teamsContext);
        break;
      case TILE_ACTIONS.PHONECALL:
        triggerPhoneCall(element, props.setTileActionAlert, teamsContext);
        break;
      case TILE_ACTIONS.VIDEOCALL:
        triggerVideoCall(element, props.setTileActionAlert, teamsContext);
        break;
      case TILE_ACTIONS.MESSAGE:
        triggerChat(element, props.setTileActionAlert, teamsContext);
        break;
    }
  };
  const buildMenuListItems = (): ReactElement<typeof MenuItem>[] => {
    const menuItems: ReactElement<typeof MenuItem>[] = [];
    props.inputArray.forEach((element) => {
      menuItems.push(
        <MenuItem onClick={() => clickMenuItem(element)}>{element}</MenuItem>
      );
    });
    return menuItems;
  };

  return (
    <>
      {props.inputArray.length > 1 && (
        <PopoverSurface>
          <MenuList>{buildMenuListItems()}</MenuList>
        </PopoverSurface>
      )}
    </>
  );
};
