import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import { putAdminControllerSetVersionUpdateSettingsResponse } from "../../../services/ApiService";
import {
  TAdminControllerGetVersionUpdateInfo,
  TAdminPagePopoverSettings,
} from "../../../types/Types";
import SyntaxHighlighter from "react-syntax-highlighter";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { ReactComponent as CopyIconRegular } from "../../../assets/images/ic_fluent_copy_24_regular.svg";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

const installCodeRaw = `# Install or Updates the Unified Contacts Powershell Module
if(Get-Module -ListAvailable -Name UnifiedContactsPS){
  Update-Module UnifiedContactsPS
} else {
  Install-Module UnifiedContactsPS
}

# Update Unified Contacts
Update-UnifiedContacts -ReleaseChannel "{{releaseChannel}}" -AppServiceAzureUrl "{{appServiceAzureUrl}}"`;

export type AdminPageContentUpdateProps = {
  versionUpdateInfo?: TAdminControllerGetVersionUpdateInfo;
  refreshVersionUpdateInfo: () => Promise<TAdminControllerGetVersionUpdateInfo>;
  triggerPopover: (popoverSettings: TAdminPagePopoverSettings) => void;
  hidePopover: () => void;
};

export function AdminPageContentUpdate(props: AdminPageContentUpdateProps) {
  const [loadingSetVersionUpdateSettings, setLoadingSetVersionUpdateSettings] =
    useState<boolean>(false);
  const [loadingUpdateInfo, setLoadingUpdateInfo] = useState<boolean>(false);
  const [installCode, setInstallCode] = useState<string>(installCodeRaw);

  const triggerUpdatePopover = useCallback(
    (buttonTrigger: boolean) => {
      if (
        buttonTrigger ||
        props.versionUpdateInfo?.updateInProgress === true ||
        props.versionUpdateInfo?.restartRequired === true
      ) {
        props.triggerPopover({
          canClose:
            !props.versionUpdateInfo?.updateInProgress &&
            !props.versionUpdateInfo?.restartRequired,
          header: <>Update to {props.versionUpdateInfo?.updateVersion}</>,
          body: (
            <>
              <div>
                To update Unified Contacts, please open the cloud shell in the{" "}
                <a
                  href="https://portal.azure.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Azure Portal
                </a>{" "}
                and paste the code shown below
              </div>
              <div className={`admin-page__update-tab__code-block`}>
                <div className="copy-button">
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Click to copy!</Tooltip>}
                  >
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(installCode);
                      }}
                    >
                      <CopyIconRegular />
                    </Button>
                  </OverlayTrigger>
                </div>

                <SyntaxHighlighter language="powershell" style={a11yDark}>
                  {installCode}
                </SyntaxHighlighter>
              </div>
            </>
          ),
          footer: (
            <>
              {!props.versionUpdateInfo?.updateInProgress &&
                !props.versionUpdateInfo?.restartRequired && (
                  <>
                    <a
                      href="https://aka.c4a8.net/ucupdate"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More Information
                    </a>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(installCode);
                        props.hidePopover();
                      }}
                    >
                      Copy and close
                    </Button>
                  </>
                )}
            </>
          ),
        });
      }
    },
    [props, installCode]
  );

  useEffect(() => {
    let newInstallCode = installCodeRaw;

    newInstallCode = newInstallCode.replaceAll(
      "{{releaseChannel}}",
      props.versionUpdateInfo?.selectedChannel ?? "stable"
    );

    if (props.versionUpdateInfo?.appServiceAzureUrl) {
      newInstallCode = newInstallCode.replaceAll(
        "{{appServiceAzureUrl}}",
        props.versionUpdateInfo.appServiceAzureUrl
      );
    } else {
      newInstallCode = newInstallCode.replaceAll(
        "{{appServiceAzureUrl}}",
        "https://portal.azure.com/.../appServices"
      );
    }

    setInstallCode(newInstallCode);
    triggerUpdatePopover(false);
  }, [props.versionUpdateInfo, triggerUpdatePopover]);

  function changeReleaseChannel(channelName: string) {
    setLoadingSetVersionUpdateSettings(true);
    putAdminControllerSetVersionUpdateSettingsResponse({
      selectedReleaseChannel: channelName,
    }).then(() => {
      setLoadingUpdateInfo(true);
      setLoadingSetVersionUpdateSettings(false);
      props.refreshVersionUpdateInfo().then(() => {
        setLoadingUpdateInfo(false);
      });
    });
  }

  function GetReleaseChannelSelectOptions(
    updateInfo: TAdminControllerGetVersionUpdateInfo
  ) {
    const availableChannels = [...new Set(updateInfo.avaliableChannels)];

    return (
      <>
        {availableChannels?.map((channelName) => (
          <option key={channelName}>{channelName}</option>
        ))}
      </>
    );
  }

  return (
    <>
      <h1>Update</h1>
      {loadingSetVersionUpdateSettings ||
      loadingUpdateInfo ||
      !props.versionUpdateInfo ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {props.versionUpdateInfo.updateAvailable ? (
              <div style={{ textAlign: "center" }}>
                <span>Update available</span>
                <h1>{props.versionUpdateInfo.updateVersion}</h1>
                <Button onClick={() => triggerUpdatePopover(true)}>
                  Update Now
                </Button>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <span>No update available</span>
                <h1>Up to date</h1>
              </div>
            )}
            <Form.Group className="mb-3" controlId="appDisplayName">
              <Form.Label>Release Channel</Form.Label>
              <Form.Select
                onChange={(event) => {
                  changeReleaseChannel(event.target.value);
                }}
                value={props.versionUpdateInfo.selectedChannel}
              >
                {GetReleaseChannelSelectOptions(props.versionUpdateInfo)}
              </Form.Select>
              <Form.Text className="text-muted">
                Select your desired release channel. We highly recommend the
                stable channel. If you want to be the first to experience new
                features select a preview channel.
              </Form.Text>
            </Form.Group>
          </Form>
        </>
      )}
      <hr/>
      <div className="admin-page__content__overview__changelog">
        <iframe title="Unified Contacts Changelog" src="https://embed-150939884.sleekplan.app/#/changelog/"></iframe>
      </div>
    </>
  );
}
