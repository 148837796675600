export default function SmallTileIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H5C5.27614 0.5 5.5 0.723858 5.5 1V2.5C5.5 2.77614 5.27614 3 5 3H1C0.723858 3 0.5 2.77614 0.5 2.5V2Z"
        stroke="#C4C4C4"
      />
      <path
        d="M0.5 5.5C0.5 5.22386 0.723858 5 1 5H5C5.27614 5 5.5 5.22386 5.5 5.5V7C5.5 7.27614 5.27614 7.5 5 7.5H1C0.723858 7.5 0.5 7.27614 0.5 7V5.5Z"
        stroke="#C4C4C4"
      />
      <path
        d="M0.5 10C0.5 9.72386 0.723858 9.5 1 9.5H5C5.27614 9.5 5.5 9.72386 5.5 10V11.5C5.5 11.7761 5.27614 12 5 12H2C1.17157 12 0.5 11.3284 0.5 10.5V10Z"
        stroke="#C4C4C4"
      />
      <path
        d="M7.5 1C7.5 0.723858 7.72386 0.5 8 0.5H11C11.8284 0.5 12.5 1.17157 12.5 2V2.5C12.5 2.77614 12.2761 3 12 3H8C7.72386 3 7.5 2.77614 7.5 2.5V1Z"
        stroke="#C4C4C4"
      />
      <path
        d="M7.5 5.5C7.5 5.22386 7.72386 5 8 5H12C12.2761 5 12.5 5.22386 12.5 5.5V7C12.5 7.27614 12.2761 7.5 12 7.5H8C7.72386 7.5 7.5 7.27614 7.5 7V5.5Z"
        stroke="#C4C4C4"
      />
      <path
        d="M7.5 10C7.5 9.72386 7.72386 9.5 8 9.5H12C12.2761 9.5 12.5 9.72386 12.5 10V10.5C12.5 11.3284 11.8284 12 11 12H8C7.72386 12 7.5 11.7761 7.5 11.5V10Z"
        stroke="#C4C4C4"
      />
    </svg>
  );
}
