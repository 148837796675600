import { AdminPageAuthentication } from "./pages/AdminPage/AdminPageAuthentication";
import { TeamsPage } from "./pages/TeamsPage/TeamsPage";
import { TeamsPageAlertService } from "./pages/TeamsPage/TeamsPageAlertService";
import { PAGE_TYPE } from "./types/Enums";

const AppRoutes = [
  {
    index: true,
    element: <AdminPageAuthentication />,
  },
  {
    path: "/v1.3.0/favorites",
    element: <TeamsPageAlertService><TeamsPage pageType={PAGE_TYPE.FAVORITES}/></TeamsPageAlertService>,
  },
  {
    path: "/v1.3.0/search",
    element: <TeamsPageAlertService><TeamsPage pageType={PAGE_TYPE.SEARCH}/></TeamsPageAlertService>,
  },
  {
    path: "/contactSearchTab",
    element: <TeamsPageAlertService><TeamsPage pageType={PAGE_TYPE.SEARCH}/></TeamsPageAlertService>,
  },
];

export default AppRoutes;
