import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { getGeneralAdminPageInfo } from "./services/ApiService";

let msalAdminPageInstance: PublicClientApplication | undefined;

export const getMsalAdminPageInstance = async (): Promise<
  PublicClientApplication | undefined
> => {
  if (!msalAdminPageInstance) {
    const adminPageAppRegInfo = await getGeneralAdminPageInfo();
    if (adminPageAppRegInfo.clientId && adminPageAppRegInfo.tenantId) {
      const msalAdminPageConfig: Configuration = {
        auth: {
          clientId: adminPageAppRegInfo.clientId,
          authority: `https://login.microsoftonline.com/${adminPageAppRegInfo.tenantId}`,
        },
      };
      msalAdminPageInstance = new PublicClientApplication(msalAdminPageConfig);
    } else {
      return undefined;
    }
  }
  return msalAdminPageInstance;
};
