import Navbar from "react-bootstrap/Navbar";
import { useAdminPageEnvironmentContext } from "../../providers/AdminPageEnvironmentContextProvider";
import { EUnifiedContactsEdition } from "../../types/Enums";
import { useMemo } from "react";

export function AdminPageHeader() {
  const environmentContext = useAdminPageEnvironmentContext();

  const shortEdition = useMemo(() => {
    return environmentContext.versionInfo?.edition?.replace(
      EUnifiedContactsEdition.COMMUNITY_EDITION,
      "CE"
    );
  }, [environmentContext.versionInfo?.edition]);

  return (
    <Navbar bg="black" style={{ color: "white" }}>
      <Navbar.Brand href="" style={{ color: "white", marginLeft: "16px" }}>
        Unified Contacts {shortEdition} Portal
      </Navbar.Brand>
      {environmentContext.versionInfo?.version}
    </Navbar>
  );
}
